import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Geschenke extends Component {

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
            <h1>Geschenke</h1>
          </div>
        </div>

        <div id="Herzen" className="row pt-10 bt-1p">
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
            <img src="./images/Laden/kauf-da-ebbs-scheens.jpg" className="w-100p" alt="Herz mit Aufdruck"
              title="Teller mit Delfin" />
          </div>
          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 pb-10 gnd-22">
            <h2>Herzen</h2>
            <p>Ist es nicht schön, wenn Geschenke von Herzen kommen, wenn wir für den Menschen dem wir eine Freude
					machen wollen, eine offenes Herz haben?<br />Aus diesem Impuls heraus, habe ich auch Herzen kreiert,
					die sich für Geldgeschenke oder die Überbringung von netten Botschaften wunderbar eignen.</p>
            <p className="gnd-22-c2"><NavLink exact to="/herzen"><b>mehr . . .</b></NavLink></p>
          </div>
        </div>

        <div id="Minischaelchen" className="row pt-10 bt-1p">
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
            <img src="./images/Laden/Minischaelchen_Beispiel_4.jpg" className="w-100p" alt="Minischälchen mit Goufrais"
              title="Minischälchen mit Goufrais" />
          </div>
          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 pb-10 gnd-22">
            <h2>Minischälchen</h2>
            <p>Am Anfang stand die Freude über einen Espresso und meine Lieblingspraline, die Maja. Extra für diese
            Köstlichkeit habe ich ein Schälchen getont. Ich hatte schon immer das Bedürfnis auch den kleinen
            Freuden genug Raum zu geben. So entstanden sie nun: die Minischälchen mit verschiedensten Mustern
					und Farben und jahreszeitbezogenen Motiven. </p>
            <p className="gnd-22-c2"><NavLink exact to="/minischaelchen"><b>mehr . . .</b></NavLink></p>
          </div>
        </div>

        <div id="Bluemchen" className="row pt-10 bt-1p">
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
            <img src="./images/Laden/Blumen/Blumen_Beispiel_6.jpg" className="w-100p" alt="Bluemchen" title="Bluemchen" />
          </div>
          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 pb-10 gnd-22">
            <h2>Blümchen & Co.</h2>
            <p>Die größte Inspiration im Bezug auf meine Keramikarbeiten erfahre ich in der Natur. Sobald ich eine
            Blüte als Modell auswähle versuche ich die "Wesenheit" zu erfahren und möchte sie so natürlich wie
            möglich nachbilden. Manchmal entstehen aber auch einfach Fantasieblumen oder Einzelstücke.
					Massenware wird man bei mir nicht finden.</p>
            <p className="gnd-22-c2"><NavLink exact to="/bluemchen"><b>mehr . . .</b></NavLink></p>
          </div>
        </div>

        <div id="heimatKRONE" className="row pt-10 bt-1p">
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
            <img src="./images/Logos/heimatkrone_logo.jpg" className="w-100p" alt="heimatKRONE" title="heimatKRONE" />
          </div>
          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 pb-10 gnd-22">
            <h2>heimatKRONE - Postkarten auf badisch!</h2>
            <p className="gnd-22-c2"><NavLink exact to="/heimatkrone"><b>mehr . . .</b></NavLink></p>
          </div>
        </div>

        <div id="Teigrollen" className="row pt-10 bt-1p">
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pb-10">
              <video src="./images/Laden/nudelhoelzer.mp4" controls width="350" poster="./images/Laden/nudelhoelzer.jpg"
                autobuffer="true" loop>
                Ihr br /owser kann dieses Video nicht wiedergeben.<br />
						Sie können ihn unter <a
                  href="./images/Laden/nudelhoelzer.jpg">VIDEO</a>
						abr /ufen.<br />
                <img src="./images/Laden/nudelhoelzer.jpg" className="w-100p" alt="Herzenston" title="Herzenston" />
              </video>
            </div>
          </div>
          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 pb-10 gnd-22">
            <h2>Nudelhölzer / Teigrollen</h2>
            <p>
              Mit diesen Nudelhölzern aus Holz kannst Du Muster auf Gebäck, Marzipan und sogar Schokolade prägen.
            </p>
          </div>
        </div>

        <div className="spacer20 bt-1p"></div>
        <div className="row" id="galerie">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
            <h2>Mehr Beispiele finden sich in der <NavLink exact to="/galerieGeschenke"><b>Galerie</b></NavLink></h2>
          </div>
        </div>
      </div>
    );
  }
}
export default Geschenke;