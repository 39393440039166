import React, { Component } from "react";

class Galerie_Malstudio extends Component {

    componentDidMount(){
        window.scrollTo(0,0);
    }

    render() {
        return (
            <div className="container">

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
                        <h1>Galerie - Malstudio</h1>
                    </div>
                </div>

                <br /><br />
                <div id="GalerieMalstudio" className="row pt-10 gnd-22">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Malstudio/herzenston_stempelteller.jpg" className="w-100p" alt="Galerie"
                            title="Galerie" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/galerie/IMG_0170.JPG" className="w-100p" alt="Galerie"
                            title="Galerie" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="\images\galerie\IMG_0174.jpg" className="w-100p" alt="Galerie"
                            title="Galerie" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="\images\galerie\IMG_0189.JPG" className="w-100p" alt="Galerie"
                            title="Galerie" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="\images\galerie\IMG_0330.JPG" className="w-100p" alt="Galerie"
                            title="Galerie" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="\images\galerie\IMG_0399.jpg" className="w-100p" alt="Galerie"
                            title="Galerie" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="\images\galerie\IMG_0401.jpg" className="w-100p" alt="Galerie"
                            title="Galerie" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="\images\galerie\IMG_0416.jpg" className="w-100p" alt="Galerie"
                            title="Galerie" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="\images\galerie\IMG_0508.jpg" className="w-100p" alt="Galerie"
                            title="Galerie" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="\images\galerie\IMG_0567.JPG" className="w-100p" alt="Galerie"
                            title="Galerie" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="\images\galerie\IMG_0568.JPG" className="w-100p" alt="Galerie"
                            title="Galerie" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="\images\galerie\IMG_0573.jpg" className="w-100p" alt="Galerie"
                            title="Galerie" />
                    </div>
                </div>

            </div>
        );
    }
}
export default Galerie_Malstudio;