import React, { Component } from "react";

class Heimatkrone extends Component {

    render() {
        return (
            <div className="container">

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
                        <h1>Geschenke / Heimatkrone</h1>
                    </div>
                </div>


                <div id="heimatKRONE" className="row pt-10 bt-1p">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Logos/heimatkrone_logo.jpg" className="w-100p" alt="Teller mit Delfin"
                            title="Teller mit Delfin" />
                    </div>
                    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 pb-10 gnd-22">
                        <h2>heimatKRONE - Postkarten auf badisch!</h2>
                    </div>
                </div>
                <br />
                <div className="pb-10 gnd-22">
                    <p>Weitere Beispiele:</p>
                </div>

                <div id="DetailsHeimatkrone" className="row pt-10 gnd-22">
                    <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8;">
                        <img src="./images/Laden/heimatkrone_muster.jpg" className="w-100p" alt="heimatKRONE" title="heimatKRONE"/>
                    </div>
                </div>

            </div>
        );
    }
}
export default Heimatkrone;