import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Footer extends Component {

  getYear() {
    var date = new Date();
    return date.getFullYear();
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <footer className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pt-20">
            <div id="footer" className="bt-1p">
              <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 fot-1">
                <span>© <span id="currentYear">{this.getYear()}</span> Herzenston - Alle Rechte vorbehalten</span>
              </div>
              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 fot-2">
                <div>
                  <NavLink exact to="/datenschutz">Datenschutz</NavLink>
                </div>
                <div>
                  <NavLink exact to="/impressum">Impressum</NavLink>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}
export default Footer;