import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class DetailsAbdruckVeranstaltung extends Component {

    render() {
        return (
            <div className="container">

                <div id="DetailsAbdruck" className="row pt-10 gnd-22 pb-10">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12;">
                        <p>Für diese Veranstaltung plane ich Termine außerhalb der Öffnungszeiten. Sie dauert ca. 1 Stunde. <br />
                            Die Gebühr für den Sondertermin liegt bei 15 Euro. Dazu kommt noch der Preis für die Keramik, die bedruckt und bemalt werden soll.
                        </p>
                        <p>Für Rückfragen oder Terminvereinbarungen kannst du mir eine E-Mail schreiben oder anrufen. 
                            <NavLink exact to="/kontakt"> <b>Hier findest du die Kontaktdaten.</b></NavLink>
                        </p>
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3;">
                        <img src="./images/Fuss-und-Handabdruecke/Abdruck_Emma.jpeg" className="img-responsive" alt="Teller Babyfußabdruck"
                            title="Teller mit Babyfußabdruck und Stempeltechnik" />
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 pb-10">
                        <img src="./images/Fuss-und-Handabdruecke/handabdruck.png" className="w-100p" alt="Teller Handabdruck" title="Teller Handabdruck" />
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 pb-10">
                        <img src="./images/Fuss-und-Handabdruecke/herzenston_fuss.jpg" className="w-100p" alt="Abdruck Hand und Fu�"
                            title="Abdruck Hand und Fu�" />
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 pb-10">
                        <img src="./images/Fuss-und-Handabdruecke/herzenston_hand.jpg" className="w-100p" alt="Abdruck Hand und Fu�"
                            title="Abdruck Hand und Fu�" />
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3;">
                        <img src="./images/Fuss-und-Handabdruecke/Abdruck_Emil.jpeg" className="img-responsive" alt="Abdruck Hand"
                            title="Abdruck Hand eines Kindergartenkindes im Alter von 3 Jahren. Die Keramik wurde zusätzlich mit Stempeltechnik verziert." />
                    </div>
                </div>

            </div>
        );
    }
}
export default DetailsAbdruckVeranstaltung;