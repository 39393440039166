import React, { Component } from "react";

class Impressum extends Component {

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12; " style={{fontsize: "18px", lineheight:"18px"}}>	
                        Herzenston<br />
				Beate Huber<br />
				Sonnenhalde 1a<br />
				77797 Ohlsbach<br />
				Telefon: 07803/980920<br />
				E-Mail: beate.huber@herzenston.de<br /><br />
				alleinige Gesellschafterin: Beate Huber<br /><br />
				Umsatzsteuer-ID: Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz: DE280933751<br /><br />
				Die Europäische Kommission stellt unter <a
                            href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a> eine Plattform
				zur außergerichtlichen Online-Streitbeilegung (sog. OS-Plattform) bereit.<br /><br />
				Wir weisen gemäß § 36 Verbr /aucherstreitbeilegungsgesetz darauf hin, dass wir weder bereit noch
				verpflichtet sind, an einem Streitbeilegungsverfahren vor einer Verbr /aucherschlichtungsstelle
				teilzunehmen.
			        </div>
                </div>
            </div>
        );
    }
}
export default Impressum;