import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import DetailsGanzEinfach from "../subcomponents/DetailsGanzEinfach";

class Keramikmalstudio extends Component {

  state = { detailsGanzEinfach: false, detailsHandFussAbdruck: false }

  mehrStyle = {
    border: 0,
    backgroundColor: "white",
    outline: "none",
    color: "#337AB7"
  }

  toggleDetailsGanzEinfach = () => {
    if (this.state.detailsGanzEinfach === true)
      this.setState({ detailsGanzEinfach: false });
    else
      this.setState({ detailsGanzEinfach: true });
  }

  toggleDetailsHandFussAbdruck = () => {
    if (this.state.detailsHandFussAbdruck === true)
      this.setState({ detailsHandFussAbdruck: false });
    else
      this.setState({ detailsHandFussAbdruck: true });
  }

  render() {
    return (
      <div className="container">

        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-22">
            <h1 style={{textAlign: 'center'}}>Lass deine Keramik Geschichten erzählen</h1>
          </div>
        </div>
      
        <div id="Detailsganzeinfachmehr" className="row">
          <div className="col-xs-7 col-sm-7 col-md-7 col-lg-7">
            <DetailsGanzEinfach></DetailsGanzEinfach>
          </div>
          <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5" style={{paddingTop: "5%"}}>
              <img src="./images/Malstudio/Rohkeramik_1.jpeg" width="100%" alt="Rohkeramik"
                title="Rohkeramik" />
            </div>
        </div>

        <div className="spacer20 bt-1p"></div>
        <div className="row pt-10">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pb-10">
            <video src="./images/Malstudio/keramikmalstudio.mp4" controls width="100%" poster="./images/Malstudio/keramikmalstudio.jpg"
              autobuffer="true" loop>
              Ihr br /owser kann dieses Video nicht wiedergeben.<br />
                    Sie können ihn unter <a
                href="./images/Malstudio/keramikmalstudio.mp4">VIDEO</a>
					abr /ufen.<br />
              <img src="./images/Malstudio/keramikmalstudio.jpg" className="w-100p" alt="Herzenston" title="Herzenston" />
            </video>
          </div>
        </div>
        <div className="spacer50 bt-1p"></div>

        <div className="row" >
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-22">
            <h1 style={{textAlign: 'center'}}>Möglichkeiten der Gestaltung</h1>
          </div>
        </div>

        <div id="siebdruck" className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
            <h2>Siebdrucktechnik</h2>
            <p>Dabei wird die ausgewählte Farbe über eine Siebdruck- oder Malschablone auf das Keramikstück aufgetragen.</p>
          </div>
        </div>
        <div className="row pb-10">
          <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 pb-10">
            <img src="./images/Malstudio/herzenston_busch.jpg" className="w-100p" alt="Siebdruck Mistel"
              title="Siebdruck Seepferdchen" />
          </div>
          <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 pb-10">
            <img src="./images/Malstudio/herzenston_kuh.jpg" className="w-100p" alt="Siebdruck Kuh"
              title="Siebdruck Seepferdchen" />
          </div>
          <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 pb-10">
            <img src="./images/Malstudio/herzenston_farn.jpg" className="w-100p" alt="Siebdruck Farn" title="Siebdruck Farn" />
          </div>
          <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 pb-10">
            <img src="./images/Malstudio/seepferdchen.jpg" className="w-100p" alt="Siebdruck Farn" title="Siebrdruck Seepferdchen" />
          </div>
        </div>

        <div className="spacer20 bt-1p"></div>
        <div className="row" id="stempeltechnik">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
            <h2>Stempeltechnik</h2>
            <p>Dabei werden die Muster über Stempel auf das Keramikstück aufgetragen.</p>
          </div>
        </div>
        <div className="row pb-10">
          <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 pb-10">
            <img src="./images/Malstudio/herzenston_stempeltasse.jpg" className="w-100p" alt="Schale Stempeltechnik"
              title="Schale Stempeltechnik" />
          </div>
          <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 pb-10">
            <img src="./images/Malstudio/herzenston_stempelteller.jpg" className="w-100p" alt="Herz mit Stempeltechnik"
              title="Herz mit Stempeltechnik" />
          </div>
          <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 pb-10">
            <img src="./images/Malstudio/herzenston_hagebutte.jpg" className="w-100p" alt="Herz mit Stempeltechnik"
              title="Herz mit Stempeltechnik" />
          </div>
        </div>

        <div className="spacer20 bt-1p"></div>
        <div className="row" id="galerie">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
            <h2>Mehr Beispiele finden sich in der <NavLink exact to="/galerieMalstudio"><b>Galerie</b></NavLink></h2>
          </div>
        </div>

        <div className="spacer20 bt-1p"></div>
        <br /><br />
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-24-b">
            Weitere Informationen findest du auch auf <a
                href="https://www.instagram.com/herzenston_ohlsbach/">Instagram</a>.<br />Hier poste
				ich laufend Interessantes von Herzenston.<br />
            <div className="fb-like" data-href="https://www.facebook.com/herzenston" data-layout="standard"
              data-action="like" data-size="small" data-show-faces="true" data-share="true"></div>
            <div className="fb-follow" data-href="https://www.facebook.com/herzenston" data-layout="standard"
              data-size="small" data-show-faces="true"></div>
          </div>
        </div>
      </div>
    );
  }
}
export default Keramikmalstudio;