import React, { Component } from "react";
import $ from 'jquery'

class Termine extends Component {

  componentDidMount() {
    $.getJSON("https://partner.burdadirect-abo.com/renderers/content/json/woFOCIm5LQ_2TaYcI0M2Yh3uHwWaKme22WD5vvLZ6jSarkdRkSkTG2DfbP17_QGC7dnGN0xWQzXBbwljc0LEwJ", {
        sstatus:1
    }).done(function(json) {
        document.querySelector('#hztTermine').innerHTML = json.Body
    }).fail(function() {
        document.querySelector('#hztTermine').innerHTML = '<h1>Technischer Fehler</h1>'
    })
  }

  render() {
    return (
      <div id="hztTermine">
      </div>
    );
  }
}
export default Termine;