import React, { Component } from "react";
import '../App.css';
import { NavLink } from "react-router-dom";

class DetailsGanzEinfach extends Component {
  render() {
    return (
      <div>
        <div id="Detailsganzeinfach" className="row pb-12">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
            <h2>So einfach geht's:</h2>
            <p>Damit du zu deinem Wunschtermin kreativ werden kannst, empfehle ich dir frühzeitig einen Platz zu reservieren:
            <NavLink exact to="/termine"><b>Termine</b></NavLink></p>
          </div>
        </div>
        <div id="Detailsganzeinfach1" className="row pb-12">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
            <h3>1. Keramik auswählen.</h3>
            <p>Ein großes Sortiment an Rohlingen, wie z.B. Teller, Tassen, Schalen und Vasen, steht für dich zur Auswahl bereit.<br />
              Alle Teile sind mit einem All-Inclusive-Preis versehen. Dieser beinhaltet Rohware, Farben, Arbeitsmaterialien, Know-How und den Brand bei 1000 °C.<br />
              Je nach Größe und Form liegen die Kosten zwischen 15&euro; und 60&euro;. Das Bemalen einer Tasse oder Müslischale liegt z.B. zwischen 20&euro; und 25&euro;
            </p>
          </div>
        </div>
        <div id="Detailsganzeinfach2" className="row pb-12">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18 ">
            <h3>2. Keramik bemalen</h3>
            <p>Eine große Auswahl an Farben, Schablonen, Stempel und Malhilfsmitteln helfen dir bei der Gestaltung deines Wunschmotivs.<br />
              Gerne unterstütze ich euch mit meiner individuellen Beratung. <br />
              Die Glasuren sind ungiftig, ..., lebensmittelecht und hinterlassen keine Flecken auf der Kleidung.
            </p>
          </div>
        </div>
        <div id="Detailsganzeinfach3" className="row pb-12">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18 ">
            <h3>3. Barzahlung</h3>
          </div>
        </div>
        <div id="Detailsganzeinfach4" className="row pb-12">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
            <h3>4. Nachbearbeitung</h3>
            <p>Die fertiggestellten Stücke bleiben nun in meiner Obhut. <br />
              Bei über 1000 °C im Brennofen erhält das Geschirr dann den gewünschten Farbton und Glanz.
            </p>
          </div>
        </div>
        <div id="Detailsganzeinfach5" className="row pb-12">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
            <h3>5. Abholen und Freuen</h3>
            <p>Nach ca. 7 Tagen kannst du dein Kunstwerk abholen.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default DetailsGanzEinfach;
