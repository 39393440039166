import React, { Component } from "react";

class Datenschutz extends Component {

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12; ">
                        <h2>Datenschutzerklärung</h2>
                        <h3 id="dsg-general-intro"> </h3>
                        <p>Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von
                        personenbezogenen Daten (nachfolgend kurz „Daten“) innerhalb unseres Onlineangebotes und der mit ihm
                        verbundenen Webseiten, Funktionen und Inhalte sowie externen Onlinepräsenzen, wie z.B. unser Social Media
                        Profile auf (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick auf die verwendeten
                        Begrifflichkeiten, wie z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die Definitionen im Art.
          4 der Datenschutzgrundverordnung (DSGVO).<br />
                            <br />
                        </p>
                        <h3 id="dsg-general-controller">Verantwortlicher</h3>
                        <p><span className="tsmcontroller">Herzenston Beate Huber<br />
            Sonnenhalde 1a<br />
            77797 Ohlsbach Deutschland<br />
            E-Mailadresse: beate.huber@herzenston.de<br />
            Inhaber: Beate Huber <br />
            Link zum Impressum: https://www.herzenston.de/impressum<br />
                        </span></p>
                        <h3 id="dsg-general-datatype">Arten der verarbeiteten Daten:</h3>
                        <p>- Bestandsdaten (z.B., Namen, Adressen).<br />
          - Kontaktdaten (z.B., E-Mail, Telefonnummern).<br />
          - Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).<br />
          - Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).<br />
          - Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).<br />
                        </p>
                        <h3 id="dsg-general-datasubjects">Kategorien betroffener Personen</h3>
                        <p>Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend
          auch als „Nutzer“).<br />
                        </p>
                        <h3 id="dsg-general-purpose">Zweck der Verarbeitung</h3>
                        <p>- Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte.<br />
          - Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.<br />
          - Sicherheitsmaßnahmen.<br />
          - Reichweitenmessung/Marketing<br />
                            <span className="tsmcom"></span></p>
                        <h3 id="dsg-general-terms">Verwendete Begrifflichkeiten </h3>
                        <p>„Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare
                        natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person
                        angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
                        Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen
                        Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen,
          wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.<br />
                            <br />
          „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche
          Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden
          Umgang mit Daten.<br />
                            <br />
          „Pseudonymisierung“ die Verarbeitung personenbezogener Daten in einer Weise, dass die personenbezogenen Daten
          ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet
          werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und
          organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer
          identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.<br />
                            <br />
          „Profiling“ jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese
          personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche
          Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage,
          Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel
          dieser natürlichen Person zu analysieren oder vorherzusagen.<br />
                            <br />
          Als „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle,
          die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
          entscheidet, bezeichnet.<br />
                            <br />
          „Auftragsverarbeiter“ eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die
          personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.<br />
                        </p>
                        <h3 id="dsg-general-legalbasis">Maßgebliche Rechtsgrundlagen</h3>
                        <p>Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit. Sofern
                        die Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die Rechtsgrundlage für
                        die Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage für die
                        Verarbeitung zur Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen sowie Beantwortung von
                        Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
                        rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und die Rechtsgrundlage für die Verarbeitung zur
                        Wahrung unserer berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für den Fall, dass lebenswichtige
                        Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener
          Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.</p>
                        <h3 id="dsg-general-securitymeasures">Sicherheitsmaßnahmen</h3>
                        <p>Wir treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung des Stands der Technik, der
                        Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
                        unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte und Freiheiten
                        natürlicher Personen, geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes
          Schutzniveau zu gewährleisten.<br />
                            <br />
          Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von
          Daten durch Kontrolle des physischen Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der
          Eingabe, Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren
          eingerichtet, die eine Wahrnehmung von Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung der
          Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der
          Entwicklung, bzw. Auswahl von Hardware, Software sowie Verfahren, entsprechend dem Prinzip des Datenschutzes
          durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen (Art. 25 DSGVO).<br />
                        </p>
                        <h3 id="dsg-general-coprocessing">Zusammenarbeit mit Auftragsverarbeitern und Dritten</h3>
                        <p>Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und Unternehmen
                        (Auftragsverarbeitern oder Dritten) offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf die
                        Daten gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der
                        Daten an Dritte, wie an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b DSGVO zur Vertragserfüllung
                        erforderlich ist), Sie eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder auf Grundlage
          unserer berechtigten Interessen (z.B. beim Einsatz von Beauftragten, Webhostern, etc.). <br />
                            <br />
          Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines sog. „Auftragsverarbeitungsvertrages“
          beauftragen, geschieht dies auf Grundlage des Art. 28 DSGVO.</p>
                        <h3 id="dsg-general-thirdparty">Übermittlungen in Drittländer</h3>
                        <p>Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU) oder des Europäischen
                        Wirtschaftsraums (EWR)) verarbeiten oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder
                        Offenlegung, bzw. Übermittlung von Daten an Dritte geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer
                        (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung oder
                        auf Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher oder vertraglicher
                        Erlaubnisse, verarbeiten oder lassen wir die Daten in einem Drittland nur beim Vorliegen der besonderen
                        Voraussetzungen der Art. 44 ff. DSGVO verarbeiten. D.h. die Verarbeitung erfolgt z.B. auf Grundlage besonderer
                        Garantien, wie der offiziell anerkannten Feststellung eines der EU entsprechenden Datenschutzniveaus (z.B. für
                        die USA durch das „Privacy Shield“) oder Beachtung offiziell anerkannter spezieller vertraglicher
          Verpflichtungen (so genannte „Standardvertragsklauseln“).</p>
                        <h3 id="dsg-general-rightssubject">Rechte der betroffenen Personen</h3>
                        <p>Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf
          Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend Art. 15 DSGVO.<br />
                            <br />
          Sie haben entsprechend. Art. 16 DSGVO das Recht, die Vervollständigung der Sie betreffenden Daten oder die
          Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.<br />
                            <br />
          Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass betreffende Daten unverzüglich gelöscht
          werden, bzw. alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung der Daten zu
          verlangen.<br />
                            <br />
          Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach
          Maßgabe des Art. 20 DSGVO zu erhalten und deren Übermittlung an andere Verantwortliche zu fordern. <br />
                            <br />
          Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde
          einzureichen.</p>
                        <h3 id="dsg-general-revokeconsent">Widerrufsrecht</h3>
                        <p>Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft zu
          widerrufen</p>
                        <h3 id="dsg-general-object">Widerspruchsrecht</h3>
                        <p>Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe des Art. 21 DSGVO jederzeit
                        widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung erfolgen.
        </p>
                        <h3 id="dsg-general-cookies">Cookies und Widerspruchsrecht bei Direktwerbung</h3>
                        <p>Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer gespeichert werden. Innerhalb der
                        Cookies können unterschiedliche Angaben gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu einem
                        Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während oder auch nach seinem Besuch innerhalb
                        eines Onlineangebotes zu speichern. Als temporäre Cookies, bzw. „Session-Cookies“ oder „transiente Cookies“,
                        werden Cookies bezeichnet, die gelöscht werden, nachdem ein Nutzer ein Onlineangebot verlässt und seinen
                        br /owser schließt. In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs in einem Onlineshop oder ein
                        Login-Status gespeichert werden. Als „permanent“ oder „persistent“ werden Cookies bezeichnet, die auch nach
                        dem Schließen des br /owsers gespeichert bleiben. So kann z.B. der Login-Status gespeichert werden, wenn die
                        Nutzer diese nach mehreren Tagen aufsuchen. Ebenso können in einem solchen Cookie die Interessen der Nutzer
                        gespeichert werden, die für Reichweitenmessung oder Marketingzwecke verwendet werden. Als „Third-Party-Cookie“
                        werden Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen, der das Onlineangebot betreibt,
          angeboten werden (andernfalls, wenn es nur dessen Cookies sind spricht man von „First-Party Cookies“).<br />
                            <br />
          Wir können temporäre und permanente Cookies einsetzen und klären hierüber im Rahmen unserer
          Datenschutzerklärung auf.<br />
                            <br />
          Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die
          entsprechende Option in den Systemeinstellungen ihres br /owsers zu deaktivieren. Gespeicherte Cookies können in
          den Systemeinstellungen des br /owsers gelöscht werden. Der Ausschluss von Cookies kann zu
          Funktionseinschränkungen dieses Onlineangebotes führen.<br />
                            <br />
          Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann bei
          einer Vielzahl der Dienste, vor allem im Fall des Trackings, über die US-amerikanische Seite <a
                                href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a> oder die EU-Seite <a
                                    href="http://www.youronlinechoices.com/">http://www.youronlinechoices.com/</a> erklärt werden. Des Weiteren
          kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des br /owsers erreicht werden.
          Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes genutzt werden
          können.</p>
                        <h3 id="dsg-general-erasure">Löschung von Daten</h3>
                        <p>Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18 DSGVO gelöscht oder in ihrer
                        Verarbeitung eingeschränkt. Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden
                        die bei uns gespeicherten Daten gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und
                        der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten nicht gelöscht werden,
                        weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt.
                        D.h. die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus
          handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen.<br />
                            <br />
          Nach gesetzlichen Vorgaben in Deutschland, erfolgt die Aufbewahrung insbesondere für 10 Jahre gemäß §§ 147
          Abs. 1 AO, 257 Abs. 1 Nr. 1 und 4, Abs. 4 HGB (Bücher, Aufzeichnungen, Lageberichte, Buchungsbelege,
          Handelsbücher, für Besteuerung relevanter Unterlagen, etc.) und 6 Jahre gemäß § 257 Abs. 1 Nr. 2 und 3, Abs. 4
          HGB (Handelsbr /iefe). <br />
                            <br />
          Nach gesetzlichen Vorgaben in Österreich erfolgt die Aufbewahrung insbesondere für 7 J gemäß § 132 Abs. 1 BAO
          (Buchhaltungsunterlagen, Belege/Rechnungen, Konten, Belege, Geschäftspapiere, Aufstellung der Einnahmen und
          Ausgaben, etc.), für 22 Jahre im Zusammenhang mit Grundstücken und für 10 Jahre bei Unterlagen im Zusammenhang
          mit elektronisch erbr /achten Leistungen, Telekommunikations-, Rundfunk- und Fernsehleistungen, die an
          Nichtunternehmer in EU-Mitgliedstaaten erbr /acht werden und für die der Mini-One-Stop-Shop (MOSS) in Anspruch
          genommen wird.</p>
                        <p></p>
                        <h3 id="dsg-services-contractualservices">Vertragliche Leistungen</h3>
                        <p></p>
                        <p><span className="ts-muster-content">Wir verarbeiten die Daten unserer Vertragspartner und Interessenten sowie
                        anderer Auftraggeber, Kunden, Mandanten, Klienten oder Vertragspartner (einheitlich bezeichnet als
                        „Vertragspartner“) entsprechend Art. 6 Abs. 1 lit. b. DSGVO, um ihnen gegenüber unsere vertraglichen oder
                        vorvertraglichen Leistungen zu erbr /ingen. Die hierbei verarbeiteten Daten, die Art, der Umfang und der Zweck
                        und die Erforderlichkeit ihrer Verarbeitung, bestimmen sich nach dem zugrundeliegenden Vertragsverhältnis.
            <br />
                            <br />
            Zu den verarbeiteten Daten gehören die Stammdaten unserer Vertragspartner (z.B., Namen und Adressen),
            Kontaktdaten (z.B. E-Mailadressen und Telefonnummern) sowie Vertragsdaten (z.B., in Anspruch genommene
            Leistungen, Vertragsinhalte, vertragliche Kommunikation, Namen von Kontaktpersonen) und Zahlungsdaten (z.B.,
            Bankverbindungen, Zahlungshistorie). <br />
                            <br />
            Besondere Kategorien personenbezogener Daten verarbeiten wir grundsätzlich nicht, außer wenn diese
            Bestandteile einer beauftragten oder vertragsgemäßen Verarbeitung sind. <br />
                            <br />
            Wir verarbeiten Daten, die zur Begründung und Erfüllung der vertraglichen Leistungen erforderlich sind und
            weisen auf die Erforderlichkeit ihrer Angabe, sofern diese für die Vertragspartner nicht evident ist, hin.
            Eine Offenlegung an externe Personen oder Unternehmen erfolgt nur, wenn sie im Rahmen eines Vertrags
            erforderlich ist. Bei der Verarbeitung der uns im Rahmen eines Auftrags überlassenen Daten, handeln wir
            entsprechend den Weisungen der Auftraggeber sowie der gesetzlichen Vorgaben. <br />
                            <br />
            Im Rahmen der Inanspruchnahme unserer Onlinedienste, können wir die IP-Adresse und den Zeitpunkt der
            jeweiligen Nutzerhandlung speichern. Die Speicherung erfolgt auf Grundlage unserer berechtigten Interessen,
            als auch der Interessen der Nutzer am Schutz vor Missbr /auch und sonstiger unbefugter Nutzung. Eine
            Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, außer sie ist zur Verfolgung unserer
            Ansprüche gem. Art. 6 Abs. 1 lit. f. DSGVO erforderlich oder es besteht hierzu eine gesetzliche
            Verpflichtung gem. Art. 6 Abs. 1 lit. c. DSGVO.<br />
                            <br />
            Die Löschung der Daten erfolgt, wenn die Daten zur Erfüllung vertraglicher oder gesetzlicher
            Fürsorgepflichten sowie für den Umgang mit etwaigen Gewährleistungs- und vergleichbaren Pflichten nicht mehr
            erforderlich sind, wobei die Erforderlichkeit der Aufbewahrung der Daten alle drei Jahre überprüft wird; im
            Übr /igen gelten die gesetzlichen Aufbewahrungspflichten.<br />
                        </span></p>
                        <p></p>
                        <h3 id="dsg-administration">Administration, Finanzbuchhaltung, Büroorganisation, Kontaktverwaltung</h3>
                        <p></p>
                        <p><span className="ts-muster-content">Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie Organisation
                        unseres Betriebs, Finanzbuchhaltung und Befolgung der gesetzlichen Pflichten, wie z.B. der Archivierung.
                        Hierbei verarbeiten wir dieselben Daten, die wir im Rahmen der Erbr /ingung unserer vertraglichen Leistungen
                        verarbeiten. Die Verarbeitungsgrundlagen sind Art. 6 Abs. 1 lit. c. DSGVO, Art. 6 Abs. 1 lit. f. DSGVO. Von
                        der Verarbeitung sind Kunden, Interessenten, Geschäftspartner und Websitebesucher betroffen. Der Zweck und
                        unser Interesse an der Verarbeitung liegt in der Administration, Finanzbuchhaltung, Büroorganisation,
                        Archivierung von Daten, also Aufgaben die der Aufrechterhaltung unserer Geschäftstätigkeiten, Wahrnehmung
                        unserer Aufgaben und Erbr /ingung unserer Leistungen dienen. Die Löschung der Daten im Hinblick auf
                        vertragliche Leistungen und die vertragliche Kommunikation entspricht den, bei diesen
            Verarbeitungstätigkeiten genannten Angaben.<br />
                            <br />
            Wir offenbaren oder übermitteln hierbei Daten an die Finanzverwaltung, Berater, wie z.B., Steuerberater oder
            Wirtschaftsprüfer sowie weitere Gebührenstellen und Zahlungsdienstleister.<br />
                            <br />
            Ferner speichern wir auf Grundlage unserer betriebswirtschaftlichen Interessen Angaben zu Lieferanten,
            Veranstaltern und sonstigen Geschäftspartnern, z.B. zwecks späterer Kontaktaufnahme. Diese mehrheitlich
            unternehmensbezogenen Daten, speichern wir grundsätzlich dauerhaft.<br />
                        </span></p>
                        <p></p>
                        <h3 id="dsg-contact">Kontaktaufnahme</h3>
                        <p></p>
                        <p><span className="ts-muster-content">Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon
                        oder via sozialer Medien) werden die Angaben des Nutzers zur Bearbeitung der Kontaktanfrage und deren
                        Abwicklung gem. Art. 6 Abs. 1 lit. b. (im Rahmen vertraglicher-/vorvertraglicher Beziehungen), Art. 6 Abs. 1
                        lit. f. (andere Anfragen) DSGVO verarbeitet.. Die Angaben der Nutzer können in einem
                        Customer-Relationship-Management System ("CRM System") oder vergleichbarer Anfragenorganisation gespeichert
            werden.<br />
                            <br />
            Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir überprüfen die Erforderlichkeit
            alle zwei Jahre; Ferner gelten die gesetzlichen Archivierungspflichten.</span></p>
                        <p></p>
                        <h3 id="dsg-hostingprovider">Hosting und E-Mail-Versand</h3>
                        <p></p>
                        <p><span className="ts-muster-content">Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
                        Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen,
                        Rechenkapazität, Speicherplatz und Datenbankdienste, E-Mail-Versand, Sicherheitsleistungen sowie technische
            Wartungsleistungen, die wir zum Zwecke des Betriebs dieses Onlineangebotes einsetzen. <br />
                            <br />
            Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten,
            Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses
            Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren
            Zurverfügungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss
            Auftragsverarbeitungsvertrag).</span></p>
                        <p></p>
                        <h3 id="dsg-logfiles">Erhebung von Zugriffsdaten und Logfiles</h3>
                        <p></p>
                        <p><span className="ts-muster-content">Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer berechtigten
                        Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten über jeden Zugriff auf den Server, auf dem sich
                        dieser Dienst befindet (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der abgerufenen
                        Webseite, Datei, Datum und Uhrzeit des Abr /ufs, übertragene Datenmenge, Meldung über erfolgreichen Abr /uf,
                        br /owsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite),
            IP-Adresse und der anfragende Provider.<br />
                            <br />
            Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung von Missbr /auchs- oder
            Betrugshandlungen) für die Dauer von maximal 7 Tagen gespeichert und danach gelöscht. Daten, deren weitere
            Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von
            der Löschung ausgenommen.</span></p>
                        <p></p>
                        <h3 id="dsg-ga-universal">Google Universal Analytics</h3>
                        <p></p>
                        <p><span className="ts-muster-content">Wir setzen Google Analytics in der Ausgestaltung als „<a target="_blank" rel="noopener noreferrer"
                            href="https://support.google.com/analytics/answer/2790010?hl=de&amp;ref_topic=6010376">Universal-Analytics</a>“
            ein. „Universal Analytics“ bezeichnet ein Verfahren von Google Analytics, bei dem die Nutzeranalyse auf
            Grundlage einer pseudonymen Nutzer-ID erfolgt und damit ein pseudonymes Profil des Nutzers mit Informationen
            aus der Nutzung verschiedener Geräten erstellt wird (sog. „Cross-Device-Tracking“).</span></p>
                        <p></p>
                        <h3 id="dsg-socialmedia">Onlinepräsenzen in sozialen Medien</h3>
                        <p></p>
                        <p><span className="ts-muster-content">Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und Plattformen,
                        um mit den dort aktiven Kunden, Interessenten und Nutzern kommunizieren und sie dort über unsere Leistungen
            informieren zu können.<br />
                            <br />
            Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der Europäischen Union verarbeitet
            werden können. Hierdurch können sich für die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der
            Rechte der Nutzer erschwert werden könnte. Im Hinblick auf US-Anbieter die unter dem Privacy-Shield
            zertifiziert sind, weisen wir darauf hin, dass sie sich damit verpflichten, die Datenschutzstandards der EU
            einzuhalten.<br />
                            <br />
            Ferner werden die Daten der Nutzer im Regelfall für Marktforschungs- und Werbezwecke verarbeitet. So können
            z.B. aus dem Nutzungsverhalten und sich daraus ergebenden Interessen der Nutzer Nutzungsprofile erstellt
            werden. Die Nutzungsprofile können wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb und außerhalb
            der Plattformen zu schalten, die mutmaßlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken werden
            im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und die
            Interessen der Nutzer gespeichert werden. Ferner können in den Nutzungsprofilen auch Daten unabhängig der
            von den Nutzern verwendeten Geräte gespeichert werden (insbesondere wenn die Nutzer Mitglieder der
            jeweiligen Plattformen sind und bei diesen eingeloggt sind).<br />
                            <br />
            Die Verarbeitung der personenbezogenen Daten der Nutzer erfolgt auf Grundlage unserer berechtigten
            Interessen an einer effektiven Information der Nutzer und Kommunikation mit den Nutzern gem. Art. 6 Abs. 1
            lit. f. DSGVO. Falls die Nutzer von den jeweiligen Anbietern um eine Einwilligung in die Datenverarbeitung
            gebeten werden (d.h. ihr Einverständnis z.B. über das Anhaken eines Kontrollkästchens oder Bestätigung einer
            Schaltfläche erklären) ist die Rechtsgrundlage der Verarbeitung Art. 6 Abs. 1 lit. a., Art. 7 DSGVO.<br />
                            <br />
            Für eine detaillierte Darstellung der jeweiligen Verarbeitungen und der Widerspruchsmöglichkeiten (Opt-Out),
            verweisen wir auf die nachfolgend verlinkten Angaben der Anbieter.<br />
                            <br />
            Auch im Fall von Auskunftsanfragen und der Geltendmachung von Nutzerrechten, weisen wir darauf hin, dass
            diese am effektivsten bei den Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils
            Zugriff auf die Daten der Nutzer und können direkt entsprechende Maßnahmen ergreifen und Auskünfte geben.
            Sollten Sie dennoch Hilfe benötigen, dann können Sie sich an uns wenden.<br />
                            <br />
            - Facebook (Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland) -
            Datenschutzerklärung: <a target="_blank" rel="noopener noreferrer"
                                href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/</a>, Opt-Out: <a
                                    target="_blank" rel="noopener noreferrer"
                                    href="https://www.facebook.com/settings?tab=ads">https://www.facebook.com/settings?tab=ads</a> und <a
                                        target="_blank" rel="noopener noreferrer" href="http://www.youronlinechoices.com">http://www.youronlinechoices.com</a>, Privacy
            Shield: <a target="_blank" rel="noopener noreferrer"
                                href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active</a>.<br />
                            <br />
            - Google/ YouTube (Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA) –
            Datenschutzerklärung: &nbsp;<a target="_blank" rel="noopener noreferrer"
                                href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>, Opt-Out: <a
                                    target="_blank" rel="noopener noreferrer"
                                    href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a>,
            Privacy Shield: <a target="_blank" rel="noopener noreferrer"
                                href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a>.<br />
                            <br />
            - Instagram (Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA) – Datenschutzerklärung/ Opt-Out:
            <a target="_blank" rel="noopener noreferrer"
                                href="http://instagram.com/about/legal/privacy/">http://instagram.com/about/legal/privacy/</a>.<br />
                            <br />
            - Twitter (Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA) -
            Datenschutzerklärung: <a target="_blank" rel="noopener noreferrer"
                                href="https://twitter.com/de/privacy">https://twitter.com/de/privacy</a>, Opt-Out: <a target="_blank" rel="noopener noreferrer"
                                    href="https://twitter.com/personalization">https://twitter.com/personalization</a>, Privacy Shield: <a
                                        target="_blank" rel="noopener noreferrer"
                                        href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active</a>.<br />
                            <br />
            - Pinterest (Pinterest Inc., 635 High Street, Palo Alto, CA, 94301, USA) – Datenschutzerklärung/ Opt-Out: <a
                                target="_blank" rel="noopener noreferrer"
                                href="https://about.pinterest.com/de/privacy-policy">https://about.pinterest.com/de/privacy-policy</a>.<br />
                            <br />
            - LinkedIn (LinkedIn Ireland Unlimited Company Wilton Place, Dublin 2, Irland) - Datenschutzerklärung <a
                                target="_blank" rel="noopener noreferrer"
                                href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a> ,
            Opt-Out: <a target="_blank" rel="noopener noreferrer"
                                href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out">https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a>,
            Privacy Shield:&nbsp;<a target="_blank" rel="noopener noreferrer"
                                href="https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&amp;status=Active</a>.<br />
                            <br />
            - Xing (XING AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland) - Datenschutzerklärung/ Opt-Out: <a
                                target="_blank" rel="noopener noreferrer"
                                href="https://privacy.xing.com/de/datenschutzerklaerung">https://privacy.xing.com/de/datenschutzerklaerung</a>.<br />
                            <br />
            - Wakalet (Wakelet Limited, 76 Quay Street, Manchester, M3 4PR, United Kingdom) - Datenschutzerklärung/
            Opt-Out: <a target="_blank" rel="noopener noreferrer"
                                href="https://wakelet.com/privacy.html">https://wakelet.com/privacy.html</a>.</span></p>
                        <p></p>
                        <h3 id="dsg-thirdparty-einleitung">Einbindung von Diensten und Inhalten Dritter</h3>
                        <p></p>
                        <p><span className="ts-muster-content">Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer
                        berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres
                        Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von Drittanbietern
                        ein, um deren Inhalte und Services, wie z.B. Videos oder Schriftarten einzubinden (nachfolgend einheitlich
            bezeichnet als “Inhalte”). <br />
                            <br />
            Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die IP-Adresse der Nutzer wahrnehmen, da sie
            ohne die IP-Adresse die Inhalte nicht an deren br /owser senden könnten. Die IP-Adresse ist damit für die
            Darstellung dieser Inhalte erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren jeweilige
            Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner so
            genannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet) für statistische oder
            Marketingzwecke verwenden. Durch die "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den
            Seiten dieser Website ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf dem
            Gerät der Nutzer gespeichert werden und unter anderem technische Informationen zum br /owser und
            Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes
            enthalten, als auch mit solchen Informationen aus anderen Quellen verbunden werden.</span></p>
                        <p></p>
                        <h3 id="dsg-thirdparty-googlemaps">Google Maps</h3>
                        <p></p>
                        <p><span className="ts-muster-content">Wir binden die Landkarten des Dienstes “Google Maps” des Anbieters Google
                        LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Zu den verarbeiteten Daten können
                        insbesondere IP-Adressen und Standortdaten der Nutzer gehören, die jedoch nicht ohne deren Einwilligung (im
                        Regelfall im Rahmen der Einstellungen ihrer Mobilgeräte vollzogen), erhoben werden. Die Daten können in den
            USA verarbeitet werden. Datenschutzerklärung: <a target="_blank" rel="noopener noreferrer"
                                href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>, Opt-Out: <a
                                    target="_blank" rel="noopener noreferrer"
                                    href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a>.</span>
                        </p>
                        <p></p>
                        <h3 id="dsg-facebook-plugin">Verwendung von Facebook Social Plugins</h3>
                        <p></p>
                        <p><span className="ts-muster-content">Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an
                        der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1
                        lit. f. DSGVO) Social Plugins ("Plugins") des sozialen Netzwerkes facebook.com, welches von der Facebook
            Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland betrieben wird ("Facebook"). <br />
            Hierzu können z.B. Inhalte wie Bilder, Videos oder Texte und Schaltflächen gehören, mit denen Nutzer Inhalte
            dieses Onlineangebotes innerhalb von Facebook teilen können. Die Liste und das Aussehen der Facebook Social
            Plugins kann hier eingesehen werden:&nbsp;<a target="_blank" rel="noopener noreferrer"
                                href="https://developers.facebook.com/docs/plugins/">https://developers.facebook.com/docs/plugins/</a>.<br />
                            <br />
            Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das
            europäische Datenschutzrecht einzuhalten (<a target="_blank" rel="noopener noreferrer"
                                href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active</a>).<br />
                            <br />
            Wenn ein Nutzer eine Funktion dieses Onlineangebotes aufruft, die ein solches Plugin enthält, baut sein
            Gerät eine direkte Verbindung mit den Servern von Facebook auf. Der Inhalt des Plugins wird von Facebook
            direkt an das Gerät des Nutzers übermittelt und von diesem in das Onlineangebot eingebunden. Dabei können
            aus den verarbeiteten Daten Nutzungsprofile der Nutzer erstellt werden. Wir haben daher keinen Einfluss auf
            den Umfang der Daten, die Facebook mit Hilfe dieses Plugins erhebt und informiert die Nutzer daher
            entsprechend unserem Kenntnisstand.<br />
                            <br />
            Durch die Einbindung der Plugins erhält Facebook die Information, dass ein Nutzer die entsprechende Seite
            des Onlineangebotes aufgerufen hat. Ist der Nutzer bei Facebook eingeloggt, kann Facebook den Besuch seinem
            Facebook-Konto zuordnen. Wenn Nutzer mit den Plugins interagieren, zum Beispiel den Like Button betätigen
            oder einen Kommentar abgeben, wird die entsprechende Information von Ihrem Gerät direkt an Facebook
            übermittelt und dort gespeichert. Falls ein Nutzer kein Mitglied von Facebook ist, besteht trotzdem die
            Möglichkeit, dass Facebook seine IP-Adresse in Erfahrung br /ingt und speichert. Laut Facebook wird in
            Deutschland nur eine anonymisierte IP-Adresse gespeichert.<br />
                            <br />
            Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie
            die diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz der Privatsphäre der Nutzer, können
            diese den Datenschutzhinweisen von Facebook entnehmen:&nbsp;<a target="_blank" rel="noopener noreferrer"
                                href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/</a>.<br />
                            <br />
            Wenn ein Nutzer Facebookmitglied ist und nicht möchte, dass Facebook über dieses Onlineangebot Daten über
            ihn sammelt und mit seinen bei Facebook gespeicherten Mitgliedsdaten verknüpft, muss er sich vor der Nutzung
            unseres Onlineangebotes bei Facebook ausloggen und seine Cookies löschen. Weitere Einstellungen und
            Widersprüche zur Nutzung von Daten für Werbezwecke, sind innerhalb der Facebook-Profileinstellungen
            möglich:&nbsp;<a target="_blank" rel="noopener noreferrer"
                                href="https://www.facebook.com/settings?tab=ads">https://www.facebook.com/settings?tab=ads</a> &nbsp;oder
            über die US-amerikanische Seite&nbsp;<a target="_blank" rel="noopener noreferrer"
                                href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a> &nbsp;oder die
            EU-Seite&nbsp;<a target="_blank" rel="noopener noreferrer"
                                href="http://www.youronlinechoices.com/">http://www.youronlinechoices.com/</a>. Die Einstellungen erfolgen
            plattformunabhängig, d.h. sie werden für alle Geräte, wie Desktopcomputer oder mobile Geräte
            übernommen.</span></p><a href="https://datenschutz-generator.de" className="dsg1-5"
                            target="_blank" rel="noopener noreferrer nofollow">Erstellt mit Datenschutz-Generator.de von RA Dr. Thomas Schwenke</a>
                    </div>
                </div>
            </div>
        );
    }
}
export default Datenschutz;