import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Vergangene_Veranstaltungen extends Component {

  render() {
    return (
      <div className="container">

        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
            <h1>Vergangene Veranstaltungen</h1>
          </div>
        </div>

        <div>
          <div className="gnd-22">
              <h2>11-jähriges Jubiläum</h2>
          </div>
          <div>
            <img src="./images/veranstaltungen/herzenston_jubilaeum.png" className="w-100p" alt="Jubiläum"
                title="Jubiläum" />
          </div>
          <div id="DetailsJubilaeum" className="row pt-10 gnd-22">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                <img src="./images/veranstaltungen/jubilaeumzeug4.jpg" className="w-100p" alt="Jubilaeum"
                    title="Jubilaeum" />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                <img src="./images/veranstaltungen/jubilaeumzeug5.jpg" className="w-100p" alt="Jubilaeum"
                    title="Jubilaeum" />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                <img src="./images/veranstaltungen/jubilaeumzeug2.jpg" className="w-100p" alt="Jubilaeum"
                    title="Jubilaeum" />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                <img src="./images/veranstaltungen/jubilaeumzeug6.jpg" className="w-100p" alt="Jubilaeum"
                    title="Jubilaeum" />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                <img src="./images/veranstaltungen/jubilaeumzeug7.jpg" className="w-100p" alt="Jubilaeum"
                    title="Jubilaeum" />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                <img src="./images/veranstaltungen/jubilaeumzeug8.jpg" className="w-100p" alt="Jubilaeum"
                    title="Jubilaeum" />
            </div>
          </div>

        </div>
        <br />

        

        <div className="row pt-10 bt-1p">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pb-10">
            <h2>Frühling bei Herzenston</h2>
            <p className="gnd-22">Samstag 30.März und Sonntag 31. März 2019, 13:00-17:00 Uhr
              <br /><br /><b>Lass dich verzaubern!</b>
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pb-10">
            <img src="./images/veranstaltungen/Herzenston-fruehling-2019.gif" className="w-100p" alt="Frühling" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pb-10">
            <p className="gnd-22">„Jeder Frühling trägt den Zauber eines neuen Anfangs in sich.“<br />
              Finde neue Möglichkeiten deine eigene Keramik zu gestalten und lass dich überraschen von dem Glück
              der kleinen Dinge.</p>
          </div>

        </div>

        <div className="row pt-10 bt-1p">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pb-10">
            <h2>Süßer September</h2>
            <p className="gnd-22">Sonntag 09. September 2018, 11:00-16:00 Uhr
              <br /><br /><b>Erleben, Staunen &amp; Mitnehmen</b>
            </p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pb-10">
            <video src="./images/veranstaltungen/suesser-september.mp4" controls width="100%"
              poster="./images/veranstaltungen/suesser-september.jpg" autobuffer="true" loop>
              Ihr br /owser kann dieses Video nicht wiedergeben.<br />
              Sie können ihn unter <a
                href="./images/veranstaltungen/suesser-september.mp4">https://www.herzenston.de/suesser-september.mp4</a>
              abr /ufen.<br />
              <img src="./images/veranstaltungen/suesser_september.gif" className="w-100p" alt="Süßer September"
                title="Süßer September" />
            </video>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pb-10">
            <p className="gnd-22">Tag der offenen Tür bei Herzenston:<br />
              Vorführung "Teigrollen mit Gravur" und Goufrais Verkostung.<br />
              Die Veranstaltung findet parallel zum Ohlsbacher Trödel- & Bauernmarkt der Narrenzunft statt.</p>
          </div>

        </div>



        <div className="row pb-20 bt-1p">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-24-b">
            <h1 style={{ fontsize: "32px" }}>Herzenston trifft Blütenzauber - Hol dir den Frühling nach Hause</h1>
            <p>Samstag 17.03.2018 und Sonntag 18.03.2018 jeweils von 13:00-17:00 Uhr</p>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
            <img src="./images/veranstaltungen/fruehling_2018.gif" className="w-100p" alt="Frühling" />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            Kommen Sie zu der gemeinsamen Veranstaltung von Herzenston und Blütenzauber am 17. und 18. März 2018
            nach Ohlsbach und bewundern Sie unsere
            Impressionen rund um den Frühling bei Kaffee und Kuchen. Sollten Ihnen aus der roßen Auswahl von Blumen,
            Gestecken mit nach Hause.
          </div>
        </div>


        <div className="row pt-10 bt-1p">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pb-10">
            <h2>Winterstimmung</h2>
            <p className="gnd-22">Samtag 11. November 2017, ab 13:00 Uhr
              <br /><br /><b>Erleben, Staunen &amp; Mitnehmen</b>
            </p>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 pb-10">
            <img src="./images/veranstaltungen/winterstimmung_2017.jpg" className="w-100p" alt="Herzenston Winterstimmung"
              title="Herzenston Winterstimmung" />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6	 pb-10">
            <img src="./images/veranstaltungen/winterstimmung_2017_rueck.jpg" className="w-100p" alt="Herzenston Winterstimmung"
              title="Herzenston Winterstimmung" />
          </div>
        </div>

        <div className="row pt-10 bt-1p">
          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 pb-10">
            <h2>Ohlsbacher Ferienprogramm 2017</h2>
            <p className="gnd-22">Im Rahmen des Ohlsbacher Ferienprogramms biete ich am 10. August 2017 2 Kurse an. Die
              Details zum Ferienprogramm findest du unter <a
                href="https://www.ohlsbach.de/de/leben-wohnen/bildung/jugend/">https://www.ohlsbach.de/de/leben-wohnen/bildung/jugend/</a>
            </p>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
            <img src="./images/veranstaltungen/Kindergeburtstag_im_Malstudio.JPG" className="w-100p" alt="Kindergeburtstag im Malstudio"
              title="Auswahl von Farben und Rohware für den Kindergeburtstag" />
          </div>
        </div>

        <div className="row pt-10 bt-1p">
          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
            <img src="./images/ostereier/IMG_4320.jpg" className="w-100p" alt="Ostermotiv" title="Kreation für Ostern" />
          </div>
          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 pb-10">
            <h2>Neueröffnung Keramikmalstudio &amp; Frühlingsausstellung</h2>
            <p className="gnd-22">Wann:<br />Freitag 7. April 2017, von 14:00-20:00 Uhr<br />Samstag 8. April, von 13:00 -
              17:00 Uhr
              <br /><br />Zur Eröffnung besteht die Möglichkeit dein Lieblingsei zu bemalen<b>.</b> Einführungen in
              die Keramikmalerei finden immer zur vollen Stunde statt<b>.</b>
            </p>
          </div>

        </div>

      </div>
    );
  }
}
export default Vergangene_Veranstaltungen;