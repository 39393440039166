import React, { Component } from "react";

class Herzen extends Component {

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
                        <h1>Geschenke / Herzen</h1>
                    </div>
                </div>

                <div id="Herzen" className="row pt-10 bt-1p">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/kauf-da-ebbs-scheens.jpg" className="w-100p"
                            title="Kauf da ebbs Scheens" alt="Bild"/>
                    </div>
                    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 pb-10 gnd-22">
                        <h2>Herzen</h2>
                        <p>Ist es nicht schön, wenn Geschenke von Herzen kommen, wenn wir für den Menschen dem wir eine Freude
					machen wollen, eine offenes Herz haben?<br />Aus diesem Impuls heraus, habe ich auch Herzen kreiert,
					die sich für Geldgeschenke oder die Überbringung von netten Botschaften wunderbar eignen. </p>
                    </div>
                </div>
                <br />
                <div className="pb-10 gnd-22">
                    <p>Weitere Beispiele:</p>
                </div>

                <div id="DetailsHerzen" className="row pt-10 gnd-22">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/herz_alles_gute.jpg" className="w-100p" alt="Herz"
                            title="Kindergeburtstag" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/herz_weiss_rot.jpg" className="w-100p" alt="Herz" title="Herz" />
                    </div>
                </div>


            </div>
        );
    }
}
export default Herzen;