import React, { Component } from "react";
import './App.css';
import Footer from "./components/Footer";
import Header from "./components/Header";
import Main from "./components/Main"
import {
  BrowserRouter as Router
} from "react-router-dom"
import ScrollToTop from "./components/ScrollToTop";

class App extends Component {


  render() {
    return (
      <div>
        <Router>
          <Header></Header>
          <ScrollToTop />
          <Main></Main>
          <Footer></Footer>
        </Router>
      </div>
    );
  }
}
export default App;
