import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Siebdruck extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
                        <h2>Siebdruck</h2>
                        <p>Mit gebr /auchsfertigen Siebdruckschablonen wird die Keramikfarbe in sehr feinen Strukturen auf die
                        Keramik aufgebr /acht werden. Dabei wird die ausgewählte Farbe über eine Siebdruckschablone auf das
					Keramikstück aufgetragen.</p>
                        <h3>Muster</h3>
                    </div>
                </div>
                <div className="row pb-10">
                    <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 pb-10">
                        <img src="./images/IMG_4677.jpg" className="w-100p" alt="Muster Platte" title="Muster Platte" />
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 pb-10">
                        <img src="./images/delfin.jpg" className="w-100p" alt="Siebdruck Delfin" title="Siebdruck Delfin" />
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 pb-10">
                        <img src="./images/seepferdchen.jpg" className="w-100p" alt="Siebdruck Seepferdchen"
                            title="Siebdruck Seepferdchen" />
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 pb-10">
                        <img src="./images/farn.jpg" className="w-100p" alt="Siebdruck Farn" title="Siebdruck Farn" />
                    </div>
                </div>
                <div id="DetailsSiebdruckmehr" className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
                        <h3>Beispiel Teller / Schmetterling</h3>
                    </div>
                </div>
                <div id="DetailsSiebdruck" className="row pb-10">
                    <div className="col-xs-4 col-sm-2 col-md-2 col-lg-2 pb-10">
                        <img src="./images/siebdruck1-1.png" className="w-100p" alt="Schablone auflegen" title="Schablone auflegen" />
                        <p>Schablone auflegen</p>
                    </div>
                    <div className="col-xs-4 col-sm-2 col-md-2 col-lg-2 pb-10">
                        <img src="./images/siebdruck1-2.png" className="w-100p" alt="Farbe mischen" title="Farbe mischen" />
                        <p>Farbe mischen</p>
                    </div>
                    <div className="col-xs-4 col-sm-2 col-md-2 col-lg-2 pb-10">
                        <img src="./images/siebdruck1-3.png" className="w-100p" alt="Farbe auftragen" title="Farbe auftragen" />
                        <p>Farbe auftragen</p>
                    </div>
                    <div className="col-xs-4 col-sm-2 col-md-2 col-lg-2 pb-10">
                        <img src="./images/siebdruck1-5.png" className="w-100p" alt="Schablone abziehen" title="Schablone abziehen" />
                        <p>Schablone abziehen</p>
                    </div>
                    <div className="col-xs-4 col-sm-2 col-md-2 col-lg-2 pb-10">
                        <img src="./images/siebdruck1-6.png" className="w-100p" alt="Keramik glasieren" title="Keramik glassieren" />
                        <p>glasieren</p>
                    </div>
                    <div className="col-xs-4 col-sm-2 col-md-2 col-lg-2 pb-10">
                        <img src="./images/siebdruck1-6.png" className="w-100p" alt="fertige Keramik" title="fertige Keramik" />
                        <p>Fertig ist das Lieblingsstück</p>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pb-10">
                        <p className="gnd-22-c2"><NavLink exact to="/keramikmalstudio"><b>zurück</b></NavLink></p>
                    </div>

                </div>

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-24-b">
                        Weitere Informationen findest du auch auf Facebook unterer <a href="https://www.facebook.com/herzenston">https://www.facebook.com/herzenston</a>
                        .<br />Hier poste ich laufend Interessantes von Herzenston.<br />
                        <div className="fb-like" data-href="https://www.facebook.com/herzenston" data-layout="standard"
                            data-action="like" data-size="small" data-show-faces="true" data-share="true"></div>
                        <div className="fb-follow" data-href="https://www.facebook.com/herzenston" data-layout="standard"
                            data-size="small" data-show-faces="true"></div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Siebdruck;