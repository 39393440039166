export function beforeOrAt(date){
    date.setHours(24)
    const today = new Date()
    return today <= date
}

export function fromUntil(from, until){
    from.setHours(24)
    until.setHours(24)
    const today = new Date()
    return today >= from && today <= until
}
