import React, { Component, Fragment } from "react";
import {
  Switch,
  Route,
  Router,
  Redirect
} from "react-router-dom"
import Start from "./Start";
import Keramikmalstudio from "./Keramikmalstudio";
import Geschenke from "./Geschenke";
import Veranstaltungen from "./Veranstaltungen";
import Kontakt from "./Kontakt";
import Siebdruck from "./Siebdruck"
import Stempeltechnik from "./Stempeltechnik";
import Herzen from "./Herzen";
import Minischaelchen from "./Minischaelchen";
import Bluemchen from "./Bluemchen";
import Heimatkrone from "./Heimatkrone";
import Papercut from "./Papercut";
import Teigrollen from "./Teigrollen";
import Keramikmalnacht from "./Keramikmalnacht";
import Kindergeburtstag from "./Kindergeburtstag";
import Datenschutz from "./Datenschutz";
import Impressum from "./Impressum";
import Galerie_Geschenke from "./Galerie_Geschenke"
import Galerie_Malstudio from "./Galerie_Malstudio"
import Termine from "./Termine";
import Vergangene_Veranstaltungen from "./Vergangene_Veranstaltungen";
import ScrollToTop from "./ScrollToTop";

class Main extends Component {

  render() {
    return (
        <Switch>
          <Route path="/start"> <Start></Start></Route>
          <Route path="/keramikmalstudio"> <Keramikmalstudio></Keramikmalstudio></Route>
          <Route path="/geschenke"> <Geschenke></Geschenke> </Route>
          <Route path="/veranstaltungen"> <Veranstaltungen></Veranstaltungen></Route>
          <Route path="/kontakt"> <Kontakt></Kontakt></Route>
          <Route path="/siebdruck"><Siebdruck></Siebdruck></Route>
          <Route path="/stempeltechnik"><Stempeltechnik></Stempeltechnik></Route>
          <Route path="/herzen"><Herzen></Herzen></Route>
          <Route path="/minischaelchen"><Minischaelchen></Minischaelchen></Route>
          <Route path="/bluemchen"><Bluemchen></Bluemchen></Route>
          <Route path="/heimatkrone"><Heimatkrone></Heimatkrone></Route>
          <Route path="/papercut"><Papercut></Papercut></Route>
          <Route path="/teigrollen"><Teigrollen></Teigrollen></Route>
          <Route path="/keramikmalnacht"><Keramikmalnacht></Keramikmalnacht></Route>
          <Route path="/kindergeburtstag"><Kindergeburtstag></Kindergeburtstag></Route>
          <Route path="/galerieGeschenke"><Galerie_Geschenke></Galerie_Geschenke></Route>
          <Route path="/galerieMalstudio"><Galerie_Malstudio></Galerie_Malstudio></Route>
          <Route path="/datenschutz"><Datenschutz></Datenschutz></Route>
          <Route path="/impressum"><Impressum></Impressum></Route>
          <Route path="/termine"><Termine></Termine></Route>
          <Route path="/vergangeneVeranstaltungen"><Vergangene_Veranstaltungen></Vergangene_Veranstaltungen></Route>
          <Route path="/"><Redirect exact from="/" to="/start" /></Route>
        </Switch>
    );
  }
}
export default Main;