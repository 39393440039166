import React, { Component } from "react";
import { isOpen } from "../functions/isOpen.js"
import { getOpenedDays } from "../functions/isOpen.js"
import { fromUntil, beforeOrAt } from "../functions/before.js";

class Start extends Component {

  getOpenMessage() {
    var openedDays = getOpenedDays();
    if (isOpen(openedDays) === true) {
      return "Heute geöffnet!";
    } else {
      return "Heute geschlossen!";
    }
  }

  openStyle = {
    color: 'red',
    fontSize: "40px"
  };


  render() {
    return (
      <div className="container">

        <div className="row pt-20">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-24">
          { fromUntil(new Date('2024-08-08'), new Date('2024-08-08')) ?
          <div>
            <h1 style={{color: 'red'}}>Der Laden ist heute geschlossen!!!</h1>
            <img className="w-100p pb-10" src="./images/Logos/geschlossen.jpg" alt="Geschlossen"
    title="Geschlossen" />
          </div>
          : null}
            
            <h1 style={{marginBottom: 0}}>Herzlich Willkommen</h1>

            <div id="Beispiele1" className="row">
              <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3">
                <video src="./images/Laden/herzenston_video.mp4" style={{ maxwidth: 600, width: "100%" }}  autoPlay controls
                  poster="./images/Laden/herzenston_video.jpg" loop >
                  <span>Ihr br /owser kann dieses Video nicht wiedergeben.<br />
                        Sie können ihn unter <a href="./images/Laden/herzenston_video.mp4">VIDEO</a>abr /ufen.<br />
                  </span>
                  <img src="./images/Laden/herzenston_video.jpg" className="w-100p" alt="Herzenston" title="Herzenston" />
                </video>
              </div>
              <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 ">
                <img className="w-100p pb-10" src="./images/Laden/herzenston_raum.jpg" alt="Herzenston Raum"
                  title="Herzenston Raum für Kreativität und Kommunikation" />
              </div>
              <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3">
                <img src="./images/Laden/herzenston_zauberhaft.jpg" className="w-100p pb-10" alt="Keramikmalstudio Farbenregal"
                  title="Regal mit den Farben zur Auswahl" />
              </div>
              <div className="col-xs-6 col-sm-3 col-md-3 col-lg-4;">
                <img src="./images/Laden/herzenston_wald.jpg" className="w-100p pb-10" alt="herzenston Eingangsbereich"
                  title="Eingang zum Laden und Malstudio" />
              </div>
            </div>
            <br />
				    <p>
              Mein Name ist Beate.<br /> <br />
              Blicke ich auf meinen  bisherigen Lebensweg zurück, könnte mein zweiter Vorname "Kreativ" lauten.<br /><br />
              In den letzten Jahren begeisterte mich die Gestaltung von Ton in Verbindung mit Farben und Mustern.<br /><br />
              Ich fühle mich der Natur sehr verbunden und entdecke auch in der kleinsten Blume ihre Schönheit. <br />
              Meine Keramikarbeiten fallen in der Regel etwas kleiner aus, sind mit Liebe gefertigt und sollen Freude und Lebendigkeit in die Welt hinaustragen.<br /><br />
              Im Dezember 2014 habe ich den ersten Teil meines Ladens eröffnet, damit die von mir gefertigten Tonsachen eine Bühne erhalten.<br /><br />
              Der nächste Schritt folgte im April 2017 mit der Erweiterung des Ladens durch ein Keramikmalstudio.
              Hiermit habe ich einen Raum geschaffen, der es auch meinen Kunden ermöglicht, kreativ zu sein.<br /><br />
            </p>

            <div id="Beispiele2" className="row">
              <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3">
                <img src="./images/Malstudio/herzenston_farben.jpg" className="w-100p pb-10" alt="Kermaikmalstudio Pinsel"
                  title="Pinsel in den unterschiedlichsten Größen" />
              </div>
              <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3">
                <img src="./images/Malstudio/herzenston_technik.jpg" className="w-100p pb-10" alt="Keramikmalstudio Schablonen"
                  title="Wähle aus einer Vielzahl von Schablonen" />
              </div>
              <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3">
                <img src="./images/Malstudio/herzenston_beispiele.jpg" className="w-100p pb-10"
                  alt="Keramikmalstudio Siebdruck Schablonen"
                  title="Wähle aus einer vielzahl von Siebdruck-Schablonen" />
              </div>
              <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3">
                <img src="./images/Malstudio/keramikmalstudio-stempel.jpg" className="w-100p pb-10" alt="Keramikmalstudio Stempel"
                  title="Wähle aus einer Vielzahl von Stempeln" />
              </div>
            </div>
            <br />
            <p>
              Schön, dass du meine Webseite besuchst. So habe ich die Möglichkeit, dir einen Einblick in die Welt von Herzenston zu ermöglichen. <br />
              Konnte ich dich inspirieren?<br />
              Dann freue ich mich, dich persönlich kennenzulernen.
            </p>

          </div>
        </div>
        <br />
        <div id="WeitereInfos" className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-24">
            Weitere Informationen findest du auch auf Instagram unter <a
                href="https://www.instagram.com/herzenston_ohlsbach/">Instagram</a>.<br />Hier poste
				ich laufend Interessantes von Herzenston.<br />
          </div>
        </div>
        <br /><br />
        <div id="Flyer" className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pb-10">
            <img src="./images/Flyer/Flyer_2023_Vorderseite.png" className="w-100p" alt="Flyer Vorderseite" title="Flyer Vorderseite" style={{ border: '1px solid rgba(192,192,192,1)'}}/>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pb-10">
            <img src="./images/Flyer/Flyer_2023_Rueckseite.png" className="w-100p" alt="Flyer Rückseite" title="Flyer Rückseite" style={{ border: '1px solid rgba(192,192,192,1)'}}/>
          </div>
        </div>
        <br /><br />
      </div>
    );
  }
}
export default Start;