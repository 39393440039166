import React, { Component } from "react";

class Papercut extends Component {

    render() {
        return (
            <div className="container">

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
                        <h1>Geschenke / Papercutdesign</h1>
                    </div>
                </div>


                <div id="Papercutdesign" className="row pt-10 bt-1p">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Logos/Papercutdesign_logo.jpg" className="w-100p" alt="Papercutdesign"
                            title="Papercutdesign" />
                    </div>
                    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 pb-10 gnd-22">
                        <h2>Papercutdesign - Postkarten Schwarzwald-Motive</h2>
                        <p>
                            Papercutdesign ist ein kleines künstlerisches Familienunternehmen aus dem Schwarzwald, das
                            Papeterieartikel (Karten & Bilder) und Dekorationen (Papier- & Wollarbeiten) anbietet.
					<br /><br />
					In Ihren Arbeiten verbindet sich die kühle Mentalität des Nordens mit den farbigen Traditionen des
					Südens. Inspiriert von Kultur und Natur entstehen so moderne Designs mit typisch traditionellen
					Motiven.
					Schwarzwaldmädchen und Kuckucksuhren werden zu bunten Hinguckern, Tier und Märchenmotive berühren
					und
					laden zum Träumen ein.
				</p>
                    </div>
                </div>
                <br />
                <div className="pb-10 gnd-22">
                    <p>Weitere Beispiele:</p>
                </div>

                <div id="DetailsPapercut" className="row pt-10 gnd-22">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <img src="./images/Laden/papercutdesign.jpg" className="w-100p" alt="Papercutdesign"
                            title="Papercutdesign" />
                    </div>
                </div>

            </div>
        );
    }
}
export default Papercut;