//get dates of specified weekday in specified month and specified Year
function getWeekdays(weekday, month, year) { //0=Sonntag, 1= Montag, ..., 6 = Samstag  ;  0=Januar, ..., 11=Dezember
    var d = new Date(year, month, 1);
    var days = [];

    // Get the first weekday in the month 
    while (d.getDay() !== weekday) {
        d.setDate(d.getDate() + 1);
    }

    // Get all the other Weekdays in the month
    while (d.getMonth() === month) {
        days.push(new Date(d.getTime()));
        d.setDate(d.getDate() + 7);
    }

    return days;
}

//checks if today is Open
export function isOpen(openedDays) {
    var d = new Date()
    var days = openedDays

    var isOpen = false

    if(d.getDay() === 5)
    {
        return true;
    }

    for(var i=0;i<days.length;i++){
        if(days[i].getDate() === d.getDate() && days[i].getMonth() === d.getMonth() && days[i].getFullYear() === d.getFullYear()) {
            isOpen = true
            console.log("offen: " + days[i]);
            break;
        }
    }

    return isOpen;
}

//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//DIESE FUNKTION ANPASSEN WENN SICH ÄNDERUNGEN ERGEBEN!!!!!!
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export function getOpenedDays(){
    var days = []
    var d = new Date();

    //hier gewünschten wochentag hinzufügen
    var thursdays = getWeekdays(4, d.getMonth(), d.getFullYear());
    var saturdays = getWeekdays(6, d.getMonth(), d.getFullYear());

    //hier gewünschtes Intervall auswählen
    days.push(thursdays[1]);    //Donnerstage 
    days.push(thursdays[3]);
    days.push(saturdays[0]);    //Samstage
    days.push(saturdays[2]);

    return days
}