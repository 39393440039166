import React, { Component } from "react";
import { NavLink } from "react-router-dom"

class Keramikmalnacht extends Component {

    render() {
        return (
            <div className="container">

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-22">
                        <h2>Gestalte Dein Lieblingsst&uuml;ck: kreativ sein, malen und einen sch&ouml;nen Abend erleben!</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 gnd-22">
                        <img src="./images/Malstudio/IMG_0119.jpg" className="img-responsive" alt="Zuckerdose" title="Zuckerdosen" />
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 gnd-22">
                        <img src="./images/Malstudio/IMG_4960.jpg" className="img-responsive" alt="Zuckerdose" title="Zuckerdosen" />
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 gnd-22">
                        <img src="./images/Malstudio/IMG_5446.jpg" className="img-responsive" alt="Zuckerdose" title="Zuckerdosen" />
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 gnd-22">
                        <img src="./images/Malstudio/IMG_6963.jpg" className="img-responsive" alt="Teekanne" title="Teekanne" />
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 gnd-22">
                        <img src="./images/Malstudio/IMG_5854.jpg" className="img-responsive" alt="Zuckerdose" title="Zuckerdosen" />
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 gnd-22">
                        <img src="./images/Malstudio/IMG_E0750.jpg" className="img-responsive" alt="Zuckerdose" title="Zuckerdosen" />
                    </div>
                </div>
                <div className="row pt-20">
                    <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 gnd-22 pt-20">
                        <p>Was erwartet dich auf einer Keramikmalnacht?</p>
                        <p>Im Mittelpunkts des Abends steht die Freude am &ldquo;Kreativ-Sein&rdquo; in Gesellschaft von Gleichgesinnten. Alles was du zum Anmalen eines
                        Keramikrohlings deiner Wahl ben&ouml;tigst, steht dir zur Verf&uuml;gung. Eine individuelle Anleitung begleitet deine eigenen Ideen und dein
                                &ldquo;Lieblingsst&uuml;ck&rdquo; entsteht.<br /><br />Damit die Keramik gebrauchsfertig wird, muss ich diese noch brennen.
                                Eine Woche nach dem Event kannst Du diese abholen.
                                </p>
                    </div>
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 gnd-22 pt-20">
                        <img src="./images/Malstudio/IMG_6527.jpg" className="img-responsive" alt="Tasse" title="Rohkeramik und gemalter Becher" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-22">
                        <h2>Termine:</h2>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-22">
                        Termine findest du unter <NavLink exact to="/veranstaltungen"><b>Veranstaltungen</b></NavLink>
                    </div>
                </div>
            </div>

        );
    }
}
export default Keramikmalnacht;