import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Stempeltechnik extends Component {

    render() {
        return (
            <div className="container">

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
                        <h2>Stempeltechnik</h2>
                        <p>Dabei werden die Muster über Stempel auf das Keramikstück aufgetragen.</p>
                        <h3>Muster</h3>
                    </div>
                </div>
                <div className="row pb-10">
                    <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 pb-10">
                        <img src="./images/stempel1-5.png" className="w-100p" alt="Schale Stempeltechnik"
                            title="Schale Stempeltechnik" />
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3 pb-10">
                        <img src="./images/Herz_Stempeltechnik.jpg" className="w-100p" alt="Herz mit Stempeltechnik"
                            title="Herz mit Stempeltechnik" />
                    </div>
                </div>
                <div id="DetailsStempeltechnikmehr" className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
                        <h3>Beispiel Schale / diverse Stempel</h3>
                    </div>
                </div>
                <div className="row pb-10">
                    <div className="col-xs-4 col-sm-2 col-md-2 col-lg-2 pb-10">
                        <img src="./images/stempel1-1.png" className="w-100p" alt="Keramik vorbereiten" title="Keramik vorbereiten" />
                        <p>Keramik vorbereiten</p>
                    </div>
                    <div className="col-xs-4 col-sm-2 col-md-2 col-lg-2 pb-10">
                        <img src="./images/stempel1-2.png" className="w-100p" alt="Stempel auswählen" title="Stempel auswählen" />
                        <p>Stempel auswählen</p>
                    </div>
                    <div className="col-xs-4 col-sm-2 col-md-2 col-lg-2 pb-10">
                        <img src="./images/stempel1-3.png" className="w-100p" alt="Farben auswählen" title="Farben auswählen" />
                        <p>Farben auswählen</p>
                    </div>
                    <div className="col-xs-4 col-sm-2 col-md-2 col-lg-2 pb-10">
                        <img src="./images/stempel1-4.png" className="w-100p" alt="Glasur mit Stempel auftragen"
                            title="Glasur mit Stempel auftragen" />
                        <p>Glasur mit Stempel auftragen</p>
                    </div>
                    <div className="col-xs-4 col-sm-2 col-md-2 col-lg-2 pb-10">
                        <img src="./images/stempel1-4a.png" className="w-100p" alt="Stempel kombinieren"
                            title="Stempel kombinieren" />
                        <p>Stempel kombinieren</p>
                    </div>
                    <div className="col-xs-4 col-sm-2 col-md-2 col-lg-2 pb-10">
                        <img src="./images/stempel1-5.png" className="w-100p" alt="fertige Keramik" title="fertige Keramik" />
                        <p>Fertig ist das Lieblingsstück</p>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pb-10">
                        <p className="gnd-22-c2"><NavLink exact to="/keramikmalstudio"><b>zurück</b></NavLink></p>
                    </div>

                </div>



                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-24-b">
                        Weitere Informationen findest du auch auf Facebook unterer <a
                            href="https://www.facebook.com/herzenston">https://www.facebook.com/herzenston</a>.<br />Hier poste
				ich laufend Interessantes von Herzenston.<br />
                        <div className="fb-like" data-href="https://www.facebook.com/herzenston" data-layout="standard"
                            data-action="like" data-size="small" data-show-faces="true" data-share="true"></div>
                        <div className="fb-follow" data-href="https://www.facebook.com/herzenston" data-layout="standard"
                            data-size="small" data-show-faces="true"></div>                        
                    </div>
                </div>

            </div>
        );
    }
}
export default Stempeltechnik;