import React, { Component } from "react";

class Kindergeburtstag extends Component {

    componentDidMount(){
        window.scrollTo(0,0);
    }

    render() {
        return (
            <div className="container">

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
                        <h1>Veranstaltungen / Kindergeburtstag</h1>
                    </div>
                </div>


                <div id="Kindergeburtstag" className="row pt-10 bt-1p">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/veranstaltungen/Kindergeburtstag_Suesse_Tassen.jpg" className="w-100p" alt="Kindergeburtstag"
                            title="Kindergeburtstag" />
                    </div>
                    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 pb-10 gnd-22">
                        <h2>Kindergeburtstag</h2>
                        <p>Das Geburtstagskind kann sich die Keramik, die Farben und das Thema bei einer Vorbesprechung selbst aussuchen. Somit gestaltet es die Feier aktiv mit.</p>
                        <ul>
                            <li>Die Buchung ist ab 6 Kindern möglich.</li>
                            <li>Altersempfehlung: ab 10 Jahren</li>
                            <li>Dauer: ca. 1.5 - 2 Stunden</li>
                            <li>Kosten: Keramik ab 20 Euro + 5 Euro Pauschale pro Kind</li>
                        </ul>
                    </div>

                </div>
                <br /><br />
                <div id="DetailsKindergeburtstag" className="row pt-10 gnd-22">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/veranstaltungen/Kindergeburtstag_Einhorn.jpg" className="w-100p" alt="Kindergeburtstag"
                            title="Kindergeburtstag" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/veranstaltungen/kindergeburtstag-2.jpeg" className="w-100p" alt="Kindergeburtstag"
                            title="Kindergeburtstag" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/veranstaltungen/kindergeburtstag-3.jpeg" className="w-100p" alt="Kindergeburtstag"
                            title="Kindergeburtstag" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/veranstaltungen/kindergeburtstag-4.jpeg" className="w-100p" alt="Kindergeburtstag"
                            title="Kindergeburtstag" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/veranstaltungen/kindergeburtstag-5.jpeg" className="w-100p" alt="Kindergeburtstag"
                            title="Kindergeburtstag" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/veranstaltungen/kindergeburtstag-6.jpg" className="w-100p" alt="Kindergeburtstag"
                            title="Kindergeburtstag" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/veranstaltungen/kindergeburtstag-7.JPG" className="w-100p" alt="Kindergeburtstag"
                            title="Kindergeburtstag" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/veranstaltungen/kindergeburtstag-8.jpeg" className="w-100p" alt="Kindergeburtstag"
                            title="Kindergeburtstag" />
                    </div>
                </div>

            </div>
        );
    }
}
export default Kindergeburtstag;