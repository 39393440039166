import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Teigrollen extends Component {

  render() {
    return (
      <div className="container">

        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
            <h1>Geschenke / Nudelhölzer</h1>
          </div>
        </div>


        <div id="Teigrollen" className="row pt-10 bt-1p">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h2>Nudelhölzer</h2>
          </div>
          <div className="row pt-10">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 pb-10">
              <video src="./images/nudelheolzer.mp4" controls width="600" poster="./images/nudelhoelzer.jpg"
                autobuffer loop>
                Ihr br /owser kann dieses Video nicht wiedergeben.<br />
						Sie können ihn unter <a
                  href="https://www.herzenston.de/images/nudelhoelzer">https://www.herzenston.de/images/nudelhoelzer.mp4</a>
						abr /ufen.<br />
                <img src="./images/nudelhoelzer.jpg" className="w-100p" alt="Herzenston" title="Herzenston" />
              </video>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12;">
            Mit diesen Nudelhölzern aus Holz kannst Du Muster auf Gebäck, Marzipan und sogar Schokolade prägen.
				<br /><br />Komme zu Herzenston und finde dein Lieblingsmuster!
			</div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12;">
            <p className="gnd-22-c2"><NavLink exact to="/geschenke"><b>zurück</b></NavLink></p>
          </div>

        </div>

      </div>
    );
  }
}
export default Teigrollen;