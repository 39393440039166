import React, { Component } from "react";

class Bluemchen extends Component {

    render() {
        return (
            <div className="container">

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
                        <h1>Geschenke / Blümchen & Co.</h1>
                    </div>
                </div>


                <div id="Bluemchen" className="row pt-10 bt-1p">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/Blumen/Blumen_Beispiel_6.jpg" className="w-100p" alt="Bluemchen" title="Bluemchen" />
                    </div>
                    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 pb-10 gnd-22">
                        <h2>Blümchen & Co.</h2>
                        <p>Die größte Inspiration im Bezug auf meine Keramikarbeiten erfahre ich in der Natur. Sobald ich eine
                        Blüte als Modell auswähle versuche ich die "Wesenheit" zu erfahren und möchte sie so natürlich wie
                        möglich nachbilden. Manchmal entstehen aber auch einfach Fantasieblumen oder Einzelstücke.
					Massenware wird man bei mir nicht finden.</p>
                    </div>
                </div>
                <br />
                <div className="pb-10 gnd-22">
                    <p>Weitere Beispiele:</p>
                </div>

                <div id="DetailsBluemchen" className="row pt-10 gnd-22">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/Blumen/Blumen_Beispiel_1.jpg" className="w-100p" alt="Bluemchen" title="Bluemchen" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/Blumen/Blumen_Beispiel_2.jpg" className="w-100p" alt="Bluemchen" title="Bluemchen" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/Blumen/Blumen_Beispiel_3.jpg" className="w-100p" alt="Bluemchen" title="Bluemchen" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/Blumen/Blumen_Beispiel_4.jpg" className="w-100p" alt="Bluemchen" title="Bluemchen" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/Blumen/Blumen_Beispiel_5.jpg" className="w-100p" alt="Bluemchen" title="Bluemchen" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/Blumen/Blumen_Beispiel_7.jpg" className="w-100p" alt="Bluemchen" title="Bluemchen" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/Blumen/Blumen_Beispiel_8.jpg" className="w-100p" alt="Bluemchen" title="Bluemchen" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/Blumen/Blumen_Beispiel_9.jpg" className="w-100p" alt="Bluemchen" title="Bluemchen" />
                    </div>
                </div>

            </div>
        );
    }
}
export default Bluemchen;