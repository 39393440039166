import React, { Component } from "react";

class Galerie_Geschenke extends Component {

    componentDidMount(){
        window.scrollTo(0,0);
    }

    render() {
        return (
            <div className="container">

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
                        <h1>Galerie - Geschenke</h1>
                    </div>
                </div>

                <br /><br />
                <div id="DetailsKindergeburtstag" className="row pt-10 gnd-22">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/galerie/bild1.jpg" className="w-100p" alt="Bild1"
                            title="Galerie" />
                    </div>
                </div>

            </div>
        );
    }
}
export default Galerie_Geschenke;