import React, { Component } from "react";

class Minischaelchen extends Component {

    render() {
        return (
            <div className="container">

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 gnd-18">
                        <h1>Geschenke / Minischälchen</h1>
                    </div>
                </div>


                <div id="Minischaelchen" className="row pt-10 bt-1p">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/Minischaelchen_Beispiel_4.jpg" className="w-100p" alt="Minischälchen mit Goufrais"
                            title="Minischälchen mit Goufrais" />
                    </div>
                    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8 pb-10 gnd-22">
                        <h2>Minischälchen</h2>
                        <p>Am Anfang stand die Freude über einen Espresso und meine Lieblingspraline, die Maja. Extra für diese
                        Köstlichkeit habe ich ein Schälchen getont. Ich hatte schon immer das Bedürfnis auch den kleinen
                        Freuden genug Raum zu geben. So entstanden sie nun: die Minischälchen mit verschiedensten Mustern
					und Farben und jahreszeitbezogenen Motiven.</p>
                    </div>
                </div>
                <br />
                <div className="pb-10 gnd-22">
                    <p>Weitere Beispiele:</p>
                </div>

                <div id="DetailsMinischaelchen" className="row pt-10 gnd-22">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/Minischaelchen_Beispiel_1.jpg" className="w-100p" alt="Minischälchen"
                            title="Minischälchen" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/Minischaelchen_Beispiel_2.jpg" className="w-100p" alt="Minischälchen"
                            title="Minischälchen" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/Minischaelchen_Beispiel_3.jpg" className="w-100p" alt="Minischälchen"
                            title="Minischälchen" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/Minischaelchen_Beispiel_5.jpg" className="w-100p" alt="Minischälchen"
                            title="Minischälchen" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/Minischaelchen_Beispiel_6.jpg" className="w-100p" alt="Minischälchen"
                            title="Minischälchen" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/Minischaelchen_Beispiel_7.jpg" className="w-100p" alt="Minischälchen"
                            title="Minischälchen" />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 pb-10">
                        <img src="./images/Laden/Minischaelchen_Beispiel_8.jpg" className="w-100p" alt="Minischälchen"
                            title="Minischälchen" />
                    </div>

                </div>

            </div>
        );
    }
}
export default Minischaelchen;