import React, { Component } from "react";
import {
  NavLink
} from "react-router-dom"

class Header extends Component {


  render() {
    return (
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 logo-1">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 logo-2">
                <NavLink exact to="/start" ><img className="head_logo" src="./images/Logos/herzenston_logo.png" alt="Logo"/></NavLink>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 logo-2 pt-5">
                <span className="logo-sub gnd-24">Keramikmalstudio und Geschenke</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pt-10 bt-1p">
              <nav className="navbar navbar-default navbar-static-top">
                <ul className="nav nav-pills">
                  <li><NavLink exact to="/start" activeClassName="active"><span className="nav">Start</span></NavLink></li>
                  <li><NavLink exact to="/keramikmalstudio" activeClassName="active"><span className="nav">Keramikmalstudio</span></NavLink></li>
                  <li><NavLink exact to="/geschenke" activeClassName="active"><span className="nav">Geschenke</span></NavLink></li>
                  <li><NavLink exact to="/veranstaltungen" activeClassName="active"><span className="nav">Veranstaltungen</span></NavLink></li>
                  <li><a href="https://news.herzenston.de" ><span className="nav">News</span></a></li>
                  <li><NavLink exact to="/termine"><span className="nav">Termine</span></NavLink></li>
                  <li><NavLink exact to="/kontakt" activeClassName="active"><span className="nav">Kontakt</span></NavLink></li>
                </ul>
              </nav>
            </div>
          </div>
          {/*}<div className="row">
            <h1 style={{backgroundColor: "red", textAlign: "center", color: "white", paddingTop: "10px"}}>Urlaub vom 12. bis zum 24. September 2022</h1>
          </div>{*/}
          <div className="row">
            <div className="head-space" style={{paddingBottom: "0px"}}></div>
          </div>
        </div>
    );
  }
}
export default Header;